
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {} 

.navbar-aside {
  max-width: 260px;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.075);
  z-index: 10;
  border-right: 1px solid rgba(108, 117, 125, 0.25);
}
.navbar-aside .aside-top {
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar-aside .aside-top .brand-wrap {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: inline-block;
}
.navbar-aside .aside-top .logo {
  height: 46px;
  width: auto;
  max-width: 200px;
}

.main-header {
  padding-left: 3%;
  padding-right: 3%;
  min-height: 72px;
  background-color: #fff;
  border-bottom: 1px solid rgba(108, 117, 125, 0.25);
}
.main-header .nav {
  align-items: center;
}
.main-header .nav-item > a {
  border-radius: 0.25rem;
  color: #adb5bd;
  display: block;
  text-decoration: none;
}
.main-header .nav-link:hover {
  background-color: #e9ecef;
  color: #4fa607;
}
.main-header .col-search {
  flex-grow: 0.5;
}
.main-header .col-nav {
  display: flex;
  align-items: center;
}

.nav-item img.rounded-circle {
  border: 2px solid #eee;
}
.nav-item img.rounded-circle:hover {
  border-color: #4fa607;
}

.content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.content-header .content-title {
  margin-bottom: 0;
}

.inner {
  padding: 1rem;
}

body.aside-mini .main-wrap {
  margin-left: 70px;
}
body.aside-mini .navbar-aside {
  max-width: 70px;
  overflow: visible;
  position: absolute;
  min-height: 100%;
  bottom: auto;
}
body.aside-mini .navbar-aside .aside-top {
  text-align: center;
}
body.aside-mini .navbar-aside .aside-top > div {
  flex-grow: 1;
}
body.aside-mini .navbar-aside .brand-wrap,
body.aside-mini .navbar-aside .logo {
  display: none;
}
body.aside-mini .menu-aside .menu-link {
  text-align: center;
}
body.aside-mini .menu-aside .menu-link .text {
  display: none;
}

body.aside-mini .menu-aside .submenu {
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: white;
  margin-left: 0;
  padding: 10px;
  position: absolute;
  left: 100%;
  top: 0;
  width: 180px;
}
body.aside-mini .menu-aside .menu-item {
  position: relative;
}
body.aside-mini .menu-aside .menu-item:hover .submenu {
  display: block;
  z-index: 10;
}
body.aside-mini .menu-aside .menu-item.has-submenu .menu-link:after {
  display: none;
}

.menu-aside {
  list-style: none;
  margin: 0.5rem;
  padding: 0;
}
.menu-aside a {
  display: block;
  text-decoration: none;
}
.menu-aside .menu-item {
  margin-bottom: 5px;
}
.menu-aside .menu-item .icon {
  color: #adb5bd;
  margin-right: 10px;
  font-size: 18px;
}
.menu-aside .active .icon {
  color: #4fa607;
}
.menu-aside .active {
  background-color: #ebf0fd;
}
.menu-aside .menu-link {
  padding: 10px;
  color: #29335d;
  font-weight: bold;
  border-radius: 0.3rem;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menu-aside .menu-link .text {
  vertical-align: middle;
}
.menu-aside .menu-link:hover {
  transition: 0.2s linear;
  background-color: #e9ecef;
}
.menu-aside .submenu {
  margin-left: 44px;
  display: none;
}
.menu-aside .submenu a {
  color: #6c757d;
  padding: 5px;
}
.menu-aside .submenu a:hover {
  color: #000;
}
.menu-aside .menu-item.active .submenu {
  display: block;
}
.menu-aside .menu-item.has-submenu > .menu-link:after {
  display: inline-block;
  float: right;
  position: absolute;
  right: 10px;
  top: 10px;
  margin-top: 0.6em;
  vertical-align: middle;
  content: "";
  border-top: 5px solid #adb5bd;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
}

.nav-pills .nav-link {
  color: #6c757d;
  font-weight: 500;
}
.nav-pills .nav-link:hover {
  color: #141432;
  background-color: rgba(173, 181, 189, 0.15);
}

/* Admin Header CSS */

.main-wrap {
  margin-left: 260px;
  background-color: #f8f9fa;
}

.content-main {
  padding: 30px 3%;
  margin-left: auto;
  margin-right: auto;
}

.main-header {
  padding-left: 3%;
  padding-right: 3%;
  min-height: 72px;
  background-color: #fff;
  border-bottom: 1px solid rgba(108, 117, 125, 0.25);
}
.main-header .nav {
  align-items: center;
}
.main-header .nav-item > a {
  border-radius: 0.25rem;
  color: #adb5bd;
  display: block;
  text-decoration: none;
}
.main-header .nav-link:hover {
  background-color: #e9ecef;
  color: #4fa607;
}
.main-header .col-search {
  flex-grow: 0.5;
}
.main-header .col-nav {
  display: flex;
  align-items: center;
}

.nav-item img.rounded-circle {
  border: 2px solid #eee;
}
.nav-item img.rounded-circle:hover {
  border-color: #4fa607;
}

.content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.content-header .content-title {
  margin-bottom: 0;
}

.inner {
  padding: 1rem;
}

/* Admin Header end */

@media all and (max-width: 768px) {
  .navbar-aside {
    z-index: 900;
    transform: translateX(-100%);
    overflow-x: hidden;
    transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
  }
  .navbar-aside .aside-top .logo {
    height: 40px;
  }

  .navbar-aside.show {
    visibility: visible;
    transform: translateX(0);
  }

  .mobile-offcanvas {
    visibility: hidden;
    transform: translateX(-100%);
    border-radius: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1200;
    width: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: visibility 1s ease-in-out, transform 1s ease-in-out;
  }

  .mobile-offcanvas.show {
    visibility: visible;
    transform: translateX(0);
  }


}
@media (max-width: 1200px) {
  /* tablet devices */
  .navbar-aside {
    max-width: 220px;
  }
}


@media (max-width: 1200px) {
  /* tablet devices */
  .main-wrap {
    margin-left: 220px;
  }

  .navbar-aside {
    max-width: 220px;
  }
}
@media (max-width: 992px) {
  /* small tablet devices */
  .itemlist .col-price {
    text-align: right;
  }
  .itemlist .col-check {
    display: none;
  }

  .card-header .col-check {
    display: none;
  }
}


body.offcanvas-active {
  overflow: hidden;
}

@media all and (max-width: 768px) {
  /* mobile devices */

  .navbar-aside {
    z-index: 900;
    transform: translateX(-100%);
    overflow-x: hidden;
    transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
  }
  .navbar-aside .aside-top .logo {
    height: 40px;
  }

  .navbar-aside.show {
    visibility: visible;
    transform: translateX(0);
  }

  .mobile-offcanvas {
    visibility: hidden;
    transform: translateX(-100%);
    border-radius: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1200;
    width: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
  }

  .mobile-offcanvas.show {
    visibility: visible;
    transform: translateX(0);
  }

  .main-header {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .main-header .col-nav {
    order: 1;
    width: 100%;
    margin-bottom: 0.8rem;
    margin-left: auto;
  }
  .main-header .col-search {
    flex-grow: 1;
    width: 100%;
    order: 2;
  }

  .main-wrap {
    margin-left: 0 !important;
  }

}
