.footer{ 
	background:#111; padding:30px 0px;
	font-weight:400; text-align:center;
	font-size:11px; line-height:18px; color:rgba(255, 255, 255, 0.4) 
}

/* Medium devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 768px){
	.footer {
		padding: 10px 0;
	}
  }