@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Condensed:wght@400;700&family=League+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Roboto:wght@500&display=swap');

body{ font-family: 'Roboto', sans-serif; }
h1, h2, h3, h4, h5, h6{
  font-family: 'Fjalla One', sans-serif 
}
h3{ font-size:40px; 
  line-height:52px;
  font-weight:bold;
  color:#111;
}

h4{ font-size:32px !important; 
    line-height:42px !important;
    font-weight:bold !important;
 }

.luday_navlinks{
  @apply block pr-4 pl-4 text-white rounded md:bg-transparent text-black dark:text-white hover:bg-gray-100;
  padding: 1.5rem 2rem;
  
}

.logo-img{ width:261px !important }
.nav{ padding-right:18px }

/* Grids in Content component */
.luday_grid_links{ @apply bg-black relative w-full md:w-auto md:flex-1 flex items-end justify-center overflow-hidden h-[200px] text-white tracking-widest }
.find_deal_bg{
  background: linear-gradient(rgba(13, 13, 13, 0.4),rgba(17, 15, 15, 0.4));
  position:absolute; 
  width:100%;
  height: 100%;
  z-index: 10;
  opacity: 0.4;
}
.luday_grid_texts{ @apply relative z-20 text-9xl font-extrabold text-center  text-white drop-shadow-lg pb-6 }
.luday_grid_images{ @apply absolute inset-0 w-full object-cover group-hover:scale-110 duration-1000 }

.content_about{
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  color:#444; 
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}
.content_about p{ margin-bottom: 20px}

.height_top{ @apply h-[100px] md:h-[160px] w-full }
.height_bottom{
  height:158px; width:100%
}

.accordion_links{
  @apply text-blue-600 dark:text-blue-500 hover:underline cursor-pointer
}
.userguide_header{
  @apply text-2xl pb-4 font-bold uppercase
}
.userguide_subheader{
  @apply py-2 text-lg font-bold text-green-600
}
.anchor_link_section{
  scroll-margin-top: 5em;
}
/* Media Queries for Mobile Responsiveness */
/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 960px) {
  /* @media only screen and (min-width: 600px){ */
  .luday_navlinks{ @apply  border-y }

  .luday_slider{ @apply py-0 h-full w-full px-0 mx-0 }

  img.luday_slider{ @apply object-cover }

  .luday_wrap{ @apply px-5 }
  .luday_grid_wrap{ @apply grid grid-cols-1 gap-4 }
  h3.luday_deals{ @apply text-5xl }
  .mobile-menu{ @apply right-[-100px] h-[90vh] transition duration-100 ease-in-out delay-1000 }
  .logo-img{ @apply w-40 }
  .luday_grid_images{ @apply h-[100%] }
}
@media screen and (min-width: 768px){
  .mobile-menu{ @apply right-[-100px] h-auto transition duration-100 ease-in-out delay-1000 }
  .logo-img{ @apply w-40 }
  .luday_slider{ @apply py-0 h-full w-full px-0 mx-0 }
  .luday_grid_images{ @apply h-[100%] }
}
@media screen and (width: 400px){
  .luday_slider{ @apply py-0 h-full w-full px-0 mx-0 }
}
@media screen and (width: 912px){
  .luday_slider{ @apply py-0 h-full w-full px-0 mx-0 }
}

/* Medium devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 768px){
  .footer {
      padding: 10px 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up)
@media only screen and (min-width: 600px) {
  
}

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {
  .luday_navlinks{
    @apply  border-y
  }
  .luday_slider{
    @apply bg-blue-400 py-0 h-full w-full
  }
  img.luday_slider{
    @apply object-none object-center
  }
  .luday_wrap{
    @apply px-5
  }
  .luday_grid_wrap{
    @apply grid grid-cols-1 gap-4
  }
  h3.luday_deals{
    @apply text-5xl
  }
  .footer{
    @apply px-3
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {
  .luday_navlinks{
    @apply  border-y
  }

  .luday_slider{
    @apply bg-blue-400 py-0 h-full w-full
  }

  .luday_wrap{
    @apply px-5
  }
  .luday_grid_wrap{
    @apply grid grid-cols-1 gap-4
  }
  h3.luday_deals{
    @apply text-5xl
  }
  .mobile-menu{
    @apply right-[-100px] h-[90vh] transition duration-100 ease-in-out delay-1000 
  }
} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {} 
